import React, { useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { sanitizeHtml } from 'utils';
// import { ReactSVG } from 'react-svg';
// import anime from 'animejs';

//Assets
import { data } from './data';
import { useCheckMobile } from '../../../../hooks';

import './HowItWorks.scss';
export const HowItWorks = () => {
  const isMobile = useCheckMobile({ width: 767 });

  // useEffect(() => {
  //   setTimeout(() => {
  //     const elem = document.querySelectorAll('.injected-svg');

  //     for (let x = 0; x < elem.length; x++) {
  //       const elemAttributes = elem[x].getElementsByTagName('*');

  //       for (let i = 0; i < elemAttributes.length; i++) {
  //         let innerNodesGArray = elemAttributes[i].getElementsByTagName('g');

  //         for (let j = 0; j < innerNodesGArray.length; j++) {
  //           let innerNodesGPathes = innerNodesGArray[j].getElementsByTagName('path');

  //           anime({
  //             targets: innerNodesGPathes[i],
  //             translateX: [0, -1, 0, 1, 0],
  //             translateY: [0, 1, 0, -1, 0],
  //             opacity: [1, 0.5, 1],
  //             easing: 'linear',
  //             direction: 'alternative',
  //             duration: 3000,
  //             loop: true,
  //           });

  //           for (let k = 0; k < innerNodesGPathes.length; k++) {
  //             let attr = innerNodesGPathes[k].getAttribute('d');

  //             if (attr !== null) {
  //               anime({
  //                 targets: innerNodesGArray[i],
  //                 translateY: [0, -15],
  //                 delay: i * 100,
  //                 opacity: [1, 0],
  //                 easing: 'linear',
  //                 duration: 1500,
  //                 loop: true,
  //               });
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }, 200);
  // }, []);

  return (
    <section id="how-it-works" className="how-it-works">
      <div className="how-it-works__wrapper">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 0.3 }}
        >
          <span className="sub-title">How it works</span>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 0.8 }}
        >
          <h2>
            <span>{data.title}</span>
          </h2>
        </motion.div>
        <div className="how-it-works__blocks">
          {data.items.map(({ number, image, description, title }, index) => (
            <div className="how-it-works__block" key={number}>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { x: 0, opacity: 1 },
                      }
                    : {
                        visible: { opacity: 1 },
                        hidden: { opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 0.35 }}
              >
                <article>
                  <h3 className="how-it-works__block-title" dangerouslySetInnerHTML={{ __html: sanitizeHtml(title) }} />
                  <div
                    className="how-it-works__block-desc"
                    dangerouslySetInnerHTML={{ __html: sanitizeHtml(description) }}
                  />
                </article>
              </motion.div>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { x: 0, opacity: 1 },
                      }
                    : {
                        visible: { x: 0, opacity: 1, scale: 1 },
                        hidden: { x: index % 2 ? -100 : 100, opacity: 0, scale: 0.8 },
                      }
                }
                transition={{ type: 'anticipate', duration: 1.2 }}
              >
                <figure className="how-it-works__image">
                  <img src={image} alt="" />
                </figure>
              </motion.div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
