export const data = {
  title: `    
    <span>Access real-time production equipment data and documents.</span><br/>
    Offer a new level of intuitive worker support with SoftServe’s Gen AI Industrial Assistant.
  `,
  description: `
    <p>Guide your workers through complex maintenance processes and boost OEE. SoftServe’s Gen AI Industrial Assistant supports workers with real-time advice and guidance. It offers detailed instructions, root-cause analysis, and autogenerated KPI analyses.</p>
    <p>Workers gain rapid navigation through equipment manuals, procedures, and records. The Gen AI Industrial Assistant also facilitates confident knowledge transfer during onboarding.</p> 
    <p>Available using NVIDIA technology on-prem or deployment on any cloud &mdash; or the native solutions of your cloud provider.</p>
  `,
};

//Out dated text
//<span>Manufacturingis changing fast.</span><br/> SoftServe’s GenAI Industrial Assistant keeps your workers up tospeed.
