import React, { useState, useCallback, useEffect } from 'react';
import { motion } from 'framer-motion';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { sanitizeHtml } from 'utils';
import useEmblaCarousel from 'embla-carousel-react';
import cn from 'classnames';
//Assets
import { data } from './data';
import ChevronImage from '../../../../../asset/resource/Chevron-Right.svg';

import { useCheckMobile } from '../../../../hooks';

import './UseCases.scss';
import DOMPurify from 'dompurify';

const UseCases = () => {
  const isMobile = useCheckMobile({ width: 767 });
  const [emblaRef, emblaApi] = useEmblaCarousel();
  const [activeSlide, setActiveSlide] = useState(emblaApi?.selectedScrollSnap() || 0);
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);
  const [isNextDisabled, setIsNextDisabled] = useState(false);

  function handlePrev() {
    emblaApi?.scrollPrev();
  }

  function handleNext() {
    emblaApi?.scrollNext();
  }

  const trackChanges = useCallback(() => {
    setActiveSlide(emblaApi?.selectedScrollSnap());
    setIsPrevDisabled(!emblaApi?.canScrollPrev());
    setIsNextDisabled(!emblaApi?.canScrollNext());
  }, [emblaApi]);

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('scroll', trackChanges).on('init', trackChanges).on('reInit', trackChanges);
    }
  }, [emblaApi, trackChanges]);

  return (
    <section id="use-cases" className="use-cases">
      <div className="use-cases__wrapper">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 0.3 }}
        >
          <h2>
            <span className="sub-title">{data.subTitle}</span>
          </h2>
        </motion.div>
        {/* 
        <div className="use-cases__slider-wrapper">
          {data.items.map(({ number, title, description }, i) => (
            <motion.div
              key={number}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={
                isMobile
                  ? {
                      visible: { y: 0, opacity: 1 },
                    }
                  : {
                      visible: {
                        perspective: 800,
                        rotateY: -5,
                        translateY: -50,
                        rotateX: 0,
                        scale: 1,
                        filter: blur(0),
                        opacity: 1,
                      },
                      hidden: {
                        perspective: 800,
                        rotateY: 25,
                        rotateX: 20,
                        scale: 1,
                        filter: blur(0),
                        opacity: 0,
                      },
                    }
              }
              transition={{ type: 'anticipate', duration: 0.8, delay: (i - 0.2) / 2 }}
            >
              <div className="use-cases__slide" key={number}>
                <article>
                  <h3>{title}</h3>
                  <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(description) }} />
                </article>
              </div>
            </motion.div>
          ))}
        </div> */}
      </div>

      <div className="carousel__extended-wrapper">
        <div className="use-cases__carousel">
          <button
            disabled={isPrevDisabled}
            onClick={handlePrev}
            className="use-cases__carousel-btn use-cases__carousel-btn--prev"
          >
            <img src={ChevronImage} />
          </button>
          <div className="embla" ref={emblaRef}>
            <div className="embla__container">
              {data.items.map((item) => (
                <div className="embla__slide">
                  <div className="use-cases__carousel-item">
                    <div className="use-cases__item-text">
                      <h3
                        dangerouslySetInnerHTML={{ __html: sanitizeHtml(item.title) }}
                        className="use-cases__item-title"
                      />
                      <div
                        dangerouslySetInnerHTML={{ __html: sanitizeHtml(item.description) }}
                        className="use-cases__item-description"
                      />
                    </div>
                    <div className="use-cases__image-wrapper">
                      <img src={item.image} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <button
            disabled={isNextDisabled}
            onClick={handleNext}
            className="use-cases__carousel-btn use-cases__carousel-btn--next"
          >
            <img src={ChevronImage} />
          </button>

          <div className="carousel__dots">
            {data.items.map((_, idx) => (
              <div className={cn('carousel__dot', activeSlide === idx && 'carousel__dot--active')}></div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default UseCases;
