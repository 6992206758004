const navLinks = [
  {
    id: 1,
    title: 'How it Works',
    anchor: 'how-it-works',
  },
  {
    id: 2,
    title: 'How to use',
    anchor: 'how-to-use',
  },
  {
    id: 3,
    title: 'Use cases',
    anchor: 'use-cases',
  },
  {
    id: 5,
    title: 'Book a Demo',
    anchor: 'contact-us',
  },
];

export default navLinks;
