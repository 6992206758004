import React from 'react';
import { motion } from 'framer-motion';
import { sanitizeHtml } from 'utils';

import { useCheckMobile } from '../../../../hooks';
import { data } from './data.js';

import './Banner.scss';

const Banner = () => {
  const isMobile = useCheckMobile({ width: 767 });

  return (
    <div className="banner">
      <div className="banner__wrapper">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: 200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 0.5 }}
        >
          <h2 dangerouslySetInnerHTML={{ __html: sanitizeHtml(data.title) }} />
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 0.5 }}
        >
          <div className="banner__content" dangerouslySetInnerHTML={{ __html: sanitizeHtml(data.description) }} />
        </motion.div>
      </div>
    </div>
  );
};

export default Banner;
