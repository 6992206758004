import image_1 from '../../../../../asset/resource/use_case_1.png';
import image_2 from '../../../../../asset/resource/use_case_2.png';
import image_3 from '../../../../../asset/resource/use_case_3.png';

export const data = {
  title: 'Smart Agricultural Robotics',
  subTitle: 'Use Cases',
  items: [
    {
      image: image_1,
      number: 'Item 1',
      title: 'Accelerated Maintenance Procedures',
      description: `
      <p>Boost your maintenance operations and reduce production downtime with rapid Gen AI-based analysis and troubleshooting.</p><br/>
      
      <p>The Gen AI Industrial Assistant draws on your documentation to boost workflow efficiency. 
      It answers procedure-related queries and provides equipment feature insight using static data and metadata.</p><br/>
      <p>SoftServe's Gen AI Industrial Assistant was designed for process engineers, maintenance crews, technicians, 
      and automation engineers. It offers a superior maintenance strategy with a top-tier support system.</p>
      `,
    },
    {
      image: image_2,
      number: 'Item 2',
      title: 'AI-Driven Production Performance Analysis',
      description: `
      <p>Elevate decision-making with 3D data visualization. 
      Identify the top contributors to OEE KPIs. Analyze past performance and use it to forecast future operations. </p><br/>
      <p>Do you want to see OEE contribution percentages for two days, one week, or even three months? 
      The Gen AI Industrial Assistant can analyze historical data spanning different time periods. </p><br/>
      <p>Give your workers insight into future scenarios with a dynamic recommendation system. 
      Help them understand the potential outcomes of taking no action. Give them a powerful tool to explore 
      the impact of intervention. Deeper dives into production performance are now possible with Gen AI Industrial Assistant.</p>
      `,
    },
    {
      image: image_3,
      number: 'Item 3',
      title: 'Root-Cause Analysis ',
      description: `
      <p>Efficient failure analysis is at your fingertips. Get to the heart of issues and drive improvement of your OEE KPIs. No need to rely on graphs &mdash; just ask the Assistant to display the pertinent failure data.</p><br/>
      <p>Leverage detailed insights about equipment performance, such as specific downtimes within any chosen period. Proactively analyse real-time operational data from different sources including PLCs, IoT data, and SCADA. Unearth the cause of failures as they happen.</p><br/>
      <p>View the productivity of your production line as a whole or in segments over the required period.</p><br/>
      <p>Dive deep into equipment indicators to understand deviations, optimize productivity, and better anticipate future scenarios.</p><br/>
      `,
    },
  ],
};
