import how_it_works_image_1 from '../../../../../asset/resource/how_it_works_image_1.png';
import how_it_works_image_2 from '../../../../../asset/resource/how_it_works_image_2.png';
import how_it_works_image_3 from '../../../../../asset/resource/how_it_works_image_3.png';
import how_it_works_image_4 from '../../../../../asset/resource/how_it_works_image_4.png';

export const data = {
  title: 'SoftServe’s Gen AI Industrial Assistant technology',
  items: [
    {
      image: how_it_works_image_1,
      number: 'Item 1',
      title: 'Generative AI',
      description: `
      A large language model references your documentation to provide contextualized responses. The model can leverage historical documentation, as well as real-time production data.<br/><br/>
      The real-time assessment of IoT equipment data allows workers to speed up troubleshooting, planning, and decision-making. Powered by the NVIDIA NeMo™ framework or using cloud provider-specific Gen AI models. `,
    },
    {
      image: how_it_works_image_2,
      number: 'Item 4',
      title: 'Natural Language Interface',
      description: `
      A vocal interface simplifies interaction. Convert spoken language into text and receive responses from Gen AI Industrial Assistant in your spoken language. <br/><br/>
      That lets the Gen AI Industrial Assistant accelerate worker knowledge acquisition. It creates more time for task performance.<br/></br>
      Powered by NVIDIA® Riva ASR and NVIDIA TTS or cloud-specific protocols.
      `,
    },
    {
      image: how_it_works_image_3,
      number: 'Item 2',
      title: 'Dynamic 3D Models',
      description: `
      Paired with live data sourced from integrated IoT, Gen AI Industrial Assistant can simulate a production environment as a digital twin. Workers localize problems, understand system impact, and arrive at the fastest solution. <br/><br/>
      The Gen AI Industrial Assistant’s 3D environment is built on the NVIDIA Omniverse™ platform. It provides workers with a dynamic real-time production process visualization. `,
    },
    {
      image: how_it_works_image_4,
      number: 'Item 3',
      title: 'Customized Dashboard',
      description: `
      The Gen AI Industrial Assistant features an enhanced UI with a custom dashboard. It provides quick and easy access to your production knowledge bases, as well as the opportunity to provide feedback when the Assistant’s response is lacking. The dashboard enhances manuals and service guidelines with digitization and summarization &mdash; in accordance with industry standards.<br/> <br/>
      Workers access task-based data analytics. It speeds up problem-solving and optimizes solutions.`,
    },
  ],
};
