import how_to_use_icon_1 from '../../../../../asset/resource/how_to_use_icon_1.png';
import how_to_use_icon_2 from '../../../../../asset/resource/how_to_use_icon_2.png';
import how_to_use_icon_3 from '../../../../../asset/resource/how_to_use_icon_3.png';
import how_to_use_icon_4 from '../../../../../asset/resource/how_to_use_icon_4.png';

export const data = {
  title: 'How can you use SoftServe’s Gen AI Industrial Assistant?',
  items: [
    {
      image: how_to_use_icon_1,
      number: 'Item 11',
      title: 'Production Metrics Retrieval',
      content: `
        <ul>
          <li>
          Ask for standard OEE metrics, such as quality, performance, and availability. Specify production lines and timespans.
          </li>
          <li>The Gen AI Industrial Assistant compiles OEE indicators and categories. It generates reports based on your selected parameters. 
          Users can request guidance when metrics indicate unacceptable levels causing possible interruptions. </li>
        </ul>`,
    },
    {
      image: how_to_use_icon_2,
      number: 'Item 22',
      title: 'AI-Guided Maintenance',
      content: `
        <ul>
          <li>
          Identify production line problems. Then use the Gen AI Industrial Assistant to suggest possible causes.
          </li>
          <li>Workers interact with the Gen AI Industrial Assistant with natural language. 
          It references internal documentation and real-time production data to offer troubleshooting procedures. </li>
        </ul>`,
    },
    {
      image: how_to_use_icon_3,
      number: 'Item 33',
      title: 'Rapid Worker Onboarding',
      content: `
        <ul>
          <li>
          Integrate Gen AI Industrial Assistant into training programs.
           Familiarize workers quickly and reliably with internal documentation and ongoing processes. 
          </li>
          <li>A digital twin generates simulated problems. Workers improve production knowledge and troubleshooting with real-time help. 
          The Gen AI Industrial Assistant offers enhanced training procedures that reduce knowledge attrition.</li>
        </ul>`,
    },
    {
      image: how_to_use_icon_4,
      number: 'Item 4',
      title: 'Feedback, Evaluation, and Assignment Automation',
      content: `
        <ul>
          <li>
            Collect feedback about operations and the manufacturing environment. Evaluate feedback to make effective decisions and assign tasks and reminders for proactive operations. 
          </li>
          <li>Flag failures and aggregate bad information in a report for line manager evaluation. Autogenerated suggestions help improve procedures. You can also access and annotate operational historical data about task performers and the status of related tasks.</li>
        </ul>`,
    },
  ],
};
