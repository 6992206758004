import React from 'react';
import { motion } from 'framer-motion';

import RegistrationForm from '../../components/RegistrationForm/RegistrationForm';

import { useCheckMobile } from '../../../../hooks';

const FormSection = () => {
  const isMobile = useCheckMobile({ width: 1024 });

  return (
    <section id="contact-us" className="main__contact-us-form">
      <div className="main__contact-us-form-wrapper">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { x: 0, opacity: 1 },
                }
              : {
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 1, delay: 1.2 }}
        >
          <div className="main__contact-us-form-title-col">
            <h2 className="main__contact-us-form-title">
              <span>
                Let’s
                <br /> work
              </span>
              <br /> together
            </h2>
          </div>
        </motion.div>
        <RegistrationForm />
      </div>
    </section>
  );
};

export default FormSection;
